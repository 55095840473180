import { Schedule } from 'generated/backendSchemas'
import { useState, useRef, useEffect, createRef, RefObject } from 'react'
import useInterval from 'use-interval'
import { format } from 'date-fns'
import { useColorStore } from 'src/zustand/useColorStore'

type _widthEl = [
  number,
  number
]

type _widthSet = _widthEl[]

type _poses = number[]

export const SchedulePageDefault = ({ schedule, message, logo }: Schedule) => {
  const { tcolor, bcolor } = useColorStore()
  const wwwUrl = (import.meta.env.VITE_WWW_URL ?? '') as string
  const [poses, setPosition] = useState<_poses>()
  const [widths, setWidth] = useState<_widthSet>()

  const containerRefs = useRef<RefObject<HTMLDivElement>[]>([])
  const textRefs = useRef<RefObject<HTMLSpanElement>[]>([])

  if (typeof schedule != 'undefined') {
    schedule.forEach((_, idx: number) => {
      containerRefs.current[idx] = createRef<HTMLDivElement>()
      textRefs.current[idx] = createRef<HTMLSpanElement>()
    })
  }
  
  const _width:_widthSet = []
  const _poses:_poses = []

  useEffect(() => {
    if (typeof schedule != 'undefined') {
      schedule.forEach((_, idx) => {
        _width.push([Math.round(containerRefs.current[idx].current?.getBoundingClientRect().width || 0), Math.round(textRefs.current[idx].current?.getBoundingClientRect().width || 0)])
        _poses.push(0)
      })
      setWidth(_width)
      setPosition(_poses)
    }
  }, [])

  useInterval(() => {
    if (typeof schedule !== 'undefined') {
      schedule.forEach((_, idx) => {
        if (typeof widths !== 'undefined') {
          if (widths[idx][0] < widths[idx][1]) {
            if (typeof poses !== 'undefined') {
              if ((poses[idx] * -1) > widths[idx][0]) {
                poses?.splice(idx, 1, 0)
                setPosition([...poses])
              } else {
                poses?.splice(idx, 1, (poses[idx] - 1))
                setPosition( [...poses] );
              }
            }
          }
        }
      })
    }
  }, 50);

  const getLeftPsition = (idx: number) => {
    if (typeof poses !== 'undefined') {
      return poses[idx] + 'px'
    } else {
      return '0px'
    }
  }

  return (
  <div className="container schedule" style={{ background : bcolor }}>
    <div className="page-contents">
      <header className="header" style={{ display: 'block', height : '20vh' }}>
          <div style={{ display : 'flex'}}>
            <img src={wwwUrl + logo} style={{ height: '10vh', marginTop : '2vh', marginRight : '2vw' }} />
            <h1 className="header__title" style={{ fontSize: '4vw', marginTop : '4vh', color: tcolor }}>ご近隣・ご通行中の皆さまへ</h1>
          </div>
  
          <div className="header__datetime" style={{ width: 'fit-content', marginLeft: 'auto' }}>
            <div className="header__date">
              <span>{ format(new Date(), 'M') }</span>
            <span className="header__datetime-unit">月</span>
              <span>{ format(new Date(), 'd') }</span>
            <span className="header__datetime-unit">日</span>
          </div>
          <div className="header__time">
            <span className="header__datetime-unit header__time-unit">{ format(new Date(), 'a') }</span>
              <span>{ format(new Date(), 'HH:mm') }</span>
          </div>
        </div>
      </header>

      <main className="contents">
        <section className="schedule-content" style={{ height : '72vh' }}>
          <h2 className="schedule-content__title" style={{ fontSize : '5vw' }}>本日以降の作業予定</h2>
            <table style={{ width: '100%', fontSize: '2vw', fontWeight: 'bold', borderSpacing: '0.5vw', tableLayout : 'fixed' }}>
              <tbody>
          {(schedule ?? []).map((item, index) => {
            const additionalClass =
              item.day_of_week === '祝'
                ? '#DF4747'
                : item.day_of_week === '土'
                ? '#005EAC'
                : item.day_of_week === '日'
                ? '#DF4747'
                : item.is_holiday === true
                ? '#DF4747'
                : ''
            return (
              <tr key={item.date} style={{ backgroundColor : 'white' }}>
                <td style={{ width : '24vw', fontSize : '2vh', padding : '0.5vw' }}>{item.date} <span style={{ color : additionalClass }}>{item.day_of_week}</span></td>
                <td style={{ padding: '0.5vw', fontSize: '2vh', height: '8vh' }}>
                  <div style={{ overflow : 'hidden', whiteSpace : 'pre-wrap' }} ref={containerRefs.current[index]}>
                    <div style={{ position: 'relative', whiteSpace : 'nowrap', left: getLeftPsition(index) }}><span ref={ textRefs.current[index] }>{item.schedule_text}</span></div>
                  </div>
                </td>
              </tr>
            )
          })}
            </tbody>
          </table>
        </section>
      </main>

      </div>


      <footer className="footer">
        <div className="footer__note">
          <div className="marquee">
            <span>{ message }</span>
          </div>
        </div>
      </footer>

    </div>
  )
}
