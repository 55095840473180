/**
 * Generated by @openapi-codegen
 *
 * @version 0.20221124
 */
import * as reactQuery from '@tanstack/react-query'
import { useBackendContext, BackendContext } from './backendContext'
import type * as Fetcher from './backendFetcher'
import { backendFetch } from './backendFetcher'
import type * as Schemas from './backendSchemas'

export type GetSystemError = Fetcher.ErrorWrapper<{
  status: 401
  payload: Schemas.Unauthorized
}>

export type GetSystemResponse = {
  /**
   * 電源ON時刻 HHMM
   */
  power_on?: string
  /**
   * 電源OFF時刻 HHMM
   */
  power_off?: string
  /**
   * サイネージボリューム
   */
  volume?: {
    /**
     * 音量値
     */
    value?: number
    /**
     * 設定開始時刻 HHMM
     */
    start_time?: string
    /**
     * 設定終了時刻 HHMM
     */
    end_time?: string
  }[]
  /**
   * サイネージ輝度
   */
  brightness?: {
    /**
     * 設定値
     */
    value?: number
    /**
     * 設定開始時刻 HHMM
     */
    start_time?: string
    /**
     * 設定終了時刻 HHMM
     */
    end_time?: string
  }[]
}

export type GetSystemVariables = BackendContext['fetcherOptions']

/**
 * フロント側ではなくサイネージ本体のアプリが利用する想定
 * - 電源ON/OFF時刻（例： 0700　〜　2300）
 * - 電源ON時間帯のボリューム値
 * - 電源ON時間帯の設定輝度値
 */
export const fetchGetSystem = (
  variables: GetSystemVariables,
  signal?: AbortSignal
) =>
  backendFetch<GetSystemResponse, GetSystemError, undefined, {}, {}, {}>({
    url: '/api/system',
    method: 'get',
    ...variables,
    signal
  })

/**
 * フロント側ではなくサイネージ本体のアプリが利用する想定
 * - 電源ON/OFF時刻（例： 0700　〜　2300）
 * - 電源ON時間帯のボリューム値
 * - 電源ON時間帯の設定輝度値
 */
export const useGetSystem = <TData = GetSystemResponse>(
  variables: GetSystemVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetSystemResponse, GetSystemError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useBackendContext(options)
  return reactQuery.useQuery<GetSystemResponse, GetSystemError, TData>(
    queryKeyFn({ path: '/api/system', operationId: 'getSystem', variables }),
    ({ signal }) => fetchGetSystem({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  )
}

export type GetSlideshowError = Fetcher.ErrorWrapper<{
  status: 401
  payload: Schemas.Unauthorized
}>

export type GetSlideshowResponse = {
  slides?: {
    id?: number
    /**
     * 表示タイプ pict/movie/schedule/text/weather/wbgt/vnmeter/weatherdaemon
     */
    type?:
      | 'picture'
      | 'movie'
      | 'html'
      | 'schedule'
      | 'weather'
      | 'wbgt'
      | 'vnmeter'
      | 'weatherdaemon'
      | 'camera'
      | 'zeroaccident'
      | 'aicontent'
    /**
     * 表示時間 秒数
     */
    duration?: number
    /**
     * 次のオブジェクトへのトランジション種類名
     */
    transition?: 'fade' | 'cube' | 'coverflow' | 'flip' | 'cards' | 'creative'
    color?: string
    textcolor?: string
  }[]
  color?: string
  textcolor?: string
}

export type GetSlideshowVariables = BackendContext['fetcherOptions']

/**
 * スライドショー情報の取得
 */
export const fetchGetSlideshow = (
  variables: GetSlideshowVariables,
  signal?: AbortSignal
) =>
  backendFetch<GetSlideshowResponse, GetSlideshowError, undefined, {}, {}, {}>({
    url: '/api/slideshow',
    method: 'get',
    ...variables,
    signal
  })

/**
 * スライドショー情報の取得
 */
export const useGetSlideshow = <TData = GetSlideshowResponse>(
  variables: GetSlideshowVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetSlideshowResponse, GetSlideshowError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useBackendContext(options)
  return reactQuery.useQuery<GetSlideshowResponse, GetSlideshowError, TData>(
    queryKeyFn({
      path: '/api/slideshow',
      operationId: 'getSlideshow',
      variables
    }),
    ({ signal }) =>
      fetchGetSlideshow({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  )
}

export type GetPicturePathParams = {
  /**
   * コンテンツID
   */
  contentId: string
}

export type GetPictureError = Fetcher.ErrorWrapper<{
  status: 401
  payload: Schemas.Unauthorized
}>

export type GetPictureVariables = {
  pathParams: GetPicturePathParams
} & BackendContext['fetcherOptions']

/**
 * 画像データ取得
 */
export const fetchGetPicture = (
  variables: GetPictureVariables,
  signal?: AbortSignal
) =>
  backendFetch<
    Schemas.Picture,
    GetPictureError,
    undefined,
    {},
    {},
    GetPicturePathParams
  >({
    url: '/api/contents/picture/{contentId}',
    method: 'get',
    ...variables,
    signal
  })

/**
 * 画像データ取得
 */
export const useGetPicture = <TData = Schemas.Picture>(
  variables: GetPictureVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Picture, GetPictureError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useBackendContext(options)
  return reactQuery.useQuery<Schemas.Picture, GetPictureError, TData>(
    queryKeyFn({
      path: '/api/contents/picture/{content_id}',
      operationId: 'getPicture',
      variables
    }),
    ({ signal }) =>
      fetchGetPicture({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  )
}

export type GetMoviePathParams = {
  /**
   * コンテンツID
   */
  contentId: string
}

export type GetMovieError = Fetcher.ErrorWrapper<{
  status: 401
  payload: Schemas.Unauthorized
}>

export type GetMovieVariables = {
  pathParams: GetMoviePathParams
} & BackendContext['fetcherOptions']

/**
 * 動画データ取得
 */
export const fetchGetMovie = (
  variables: GetMovieVariables,
  signal?: AbortSignal
) =>
  backendFetch<
    Schemas.Movie,
    GetMovieError,
    undefined,
    {},
    {},
    GetMoviePathParams
  >({
    url: '/api/contents/movie/{contentId}',
    method: 'get',
    ...variables,
    signal
  })

/**
 * 動画データ取得
 */
export const useGetMovie = <TData = Schemas.Movie>(
  variables: GetMovieVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Movie, GetMovieError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useBackendContext(options)
  return reactQuery.useQuery<Schemas.Movie, GetMovieError, TData>(
    queryKeyFn({
      path: '/api/contents/movie/{content_id}',
      operationId: 'getMovie',
      variables
    }),
    ({ signal }) => fetchGetMovie({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  )
}

export type GetHtmlPathParams = {
  /**
   * コンテンツID
   */
  contentId: string
}

export type GetHtmlError = Fetcher.ErrorWrapper<{
  status: 401
  payload: Schemas.Unauthorized
}>

export type GetHtmlVariables = {
  pathParams: GetHtmlPathParams
} & BackendContext['fetcherOptions']

/**
 * HTMLデータ取得
 */
export const fetchGetHtml = (
  variables: GetHtmlVariables,
  signal?: AbortSignal
) =>
  backendFetch<
    Schemas.Html,
    GetHtmlError,
    undefined,
    {},
    {},
    GetHtmlPathParams
  >({
    url: '/api/contents/html/{contentId}',
    method: 'get',
    ...variables,
    signal
  })

/**
 * HTMLデータ取得
 */
export const useGetHtml = <TData = Schemas.Html>(
  variables: GetHtmlVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Html, GetHtmlError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useBackendContext(options)
  return reactQuery.useQuery<Schemas.Html, GetHtmlError, TData>(
    queryKeyFn({
      path: '/api/contents/html/{content_id}',
      operationId: 'getHtml',
      variables
    }),
    ({ signal }) => fetchGetHtml({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  )
}


/**
 * 予定表データ取得
 */
export type GetSchedulePathParams = {
  contentId: string
}

export type GetScheduleError = Fetcher.ErrorWrapper<{
  status: 401
  payload: Schemas.Unauthorized
}>

export type GetScheduleVariables = {
  pathParams: GetSchedulePathParams
} & BackendContext['fetcherOptions']

export const fetchGetSchedule = (
  variables: GetScheduleVariables,
  signal?: AbortSignal
) =>
  backendFetch<
    Schemas.Schedule,
    GetScheduleError,
    undefined,
    {},
    {},
    GetSchedulePathParams
  >({
    url: '/api/contents/schedule/{contentId}',
    method: 'get',
    ...variables,
    signal
  })

/**
 * 予定表データ取得
 */
export const useGetSchedule = <TData = Schemas.Schedule>(
  variables: GetScheduleVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Schedule, GetScheduleError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useBackendContext(options)
  return reactQuery.useQuery<Schemas.Schedule, GetScheduleError, TData>(
    queryKeyFn({
      path: '/api/contents/schedule/{content_id}',
      operationId: 'getSchedule',
      variables
    }),
    ({ signal }) =>
      fetchGetSchedule({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  )
}


/** --------
 * AI Contents
 */
export type GetAiContentPathParams = {
  contentId: string
}

export type GetAiContentError = Fetcher.ErrorWrapper<{
  status: 401
  payload: Schemas.Unauthorized
}>

export type GetAiContentVariables = {
  pathParams: GetAiContentPathParams
} & BackendContext['fetcherOptions']

export const fetchGetAiContent = (
  variables: GetAiContentVariables,
  signal?: AbortSignal
) =>
  backendFetch<
    Schemas.AiContent,
    GetAiContentError,
    undefined,
    {},
    {},
    GetAiContentPathParams
  >({
    url: '/api/contents/aicontent/{contentId}',
    method: 'get',
    ...variables,
    signal
  })

export const useGetAiContent = <TData = Schemas.AiContent>(
  variables: GetAiContentVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.AiContent, GetAiContentError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useBackendContext(options)
  return reactQuery.useQuery<Schemas.AiContent, GetAiContentError, TData>(
    queryKeyFn({
      path: '/api/contents/aicontent/{content_id}',
      operationId: 'getAiContent',
      variables
    }),
    ({ signal }) =>
      fetchGetAiContent({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  )
}



export type GetWeatherPathParams = {
  contentId: string
}

export type GetWeatherError = Fetcher.ErrorWrapper<{
  status: 401
  payload: Schemas.Unauthorized
}>

export type GetWeatherVariables = {
  pathParams: GetWeatherPathParams
} & BackendContext['fetcherOptions']

/**
 * 週間天気予報情報取得
 */
export const fetchGetWeather = (
  variables: GetWeatherVariables,
  signal?: AbortSignal
) =>
  backendFetch<
    Schemas.Weather,
    GetWeatherError,
    undefined,
    {},
    {},
    GetWeatherPathParams
  >({
    url: '/api/contents/weather/{contentId}',
    method: 'get',
    ...variables,
    signal
  })

/**
 * 週間天気予報情報取得
 */
export const useGetWeather = <TData = Schemas.Weather>(
  variables: GetWeatherVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Weather, GetWeatherError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useBackendContext(options)
  return reactQuery.useQuery<Schemas.Weather, GetWeatherError, TData>(
    queryKeyFn({
      path: '/api/contents/weather/{content_id}',
      operationId: 'getWeather',
      variables
    }),
    ({ signal }) =>
      fetchGetWeather({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  )
}

/**
 * 無事故無災害情報取得
 */
export const fetchGetZeroAccident = (
  variables: GetZeroAccidentVariables,
  signal?: AbortSignal
) =>
  backendFetch<
    Schemas.ZeroAccident,
    GetWeatherError,
    undefined,
    {},
    {},
    GetZeroAccidentPathParams
  >({
    url: '/api/contents/zeroaccident/{contentId}',
    method: 'get',
    ...variables,
    signal
  })

/**
 * 無事故無災害情報取得
 */
export const useGetZeroAccident = <TData = Schemas.ZeroAccident>(
  variables: GetZeroAccidentVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ZeroAccident, GetWeatherError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useBackendContext(options)
  return reactQuery.useQuery<Schemas.ZeroAccident, GetWeatherError, TData>(
    queryKeyFn({
      path: '/api/contents/zeroaccident/{content_id}',
      operationId: 'getZeroaccident',
      variables
    }),
    ({ signal }) =>
      fetchGetZeroAccident({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  )
}

export type GetWbgtPathParams = {
  contentId: string
}

export type GetWbgtError = Fetcher.ErrorWrapper<{
  status: 401
  payload: Schemas.Unauthorized
}>

export type GetWbgtVariables = {
  pathParams: GetWbgtPathParams
} & BackendContext['fetcherOptions']

/**
 * WBGT情報取得
 */
export const fetchGetWbgt = (
  variables: GetWbgtVariables,
  signal?: AbortSignal
) =>
  backendFetch<
    Schemas.Wbgt,
    GetWbgtError,
    undefined,
    {},
    {},
    GetWbgtPathParams
  >({
    url: '/api/contents/wbgt/{contentId}',
    method: 'get',
    ...variables,
    signal
  })

/**
 * WBGT情報取得
 */
export const useGetWbgt = <TData = Schemas.Wbgt>(
  variables: GetWbgtVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Wbgt, GetWbgtError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useBackendContext(options)
  return reactQuery.useQuery<Schemas.Wbgt, GetWbgtError, TData>(
    queryKeyFn({
      path: '/api/contents/wbgt/{content_id}',
      operationId: 'getWbgt',
      variables
    }),
    ({ signal }) => fetchGetWbgt({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  )
}

export type GetNoiseVibrationPathParams = {
  contentId: string
}

export type GetNoiseVibrationError = Fetcher.ErrorWrapper<{
  status: 401
  payload: Schemas.Unauthorized
}>

export type GetNoiseVibrationVariables = {
  pathParams: GetNoiseVibrationPathParams
} & BackendContext['fetcherOptions']

/**
 * 騒音・振動計情報取得
 */
export const fetchGetNoiseVibration = (
  variables: GetNoiseVibrationVariables,
  signal?: AbortSignal
) =>
  backendFetch<
    Schemas.DeviceParams,
    GetNoiseVibrationError,
    undefined,
    {},
    {},
    GetNoiseVibrationPathParams
  >({
    url: '/api/contents/vnmeter/{contentId}',
    method: 'get',
    ...variables,
    signal
  })

/**
 * 騒音・振動計情報取得
 */
export const useGetNoiseVibration = <TData = Schemas.DeviceParams>(
  variables: GetNoiseVibrationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.DeviceParams,
      GetNoiseVibrationError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  console.log('useGetNoiseVibration called')

  const { fetcherOptions, queryOptions, queryKeyFn } = useBackendContext(options)

  return reactQuery.useQuery<
    Schemas.DeviceParams,
    GetNoiseVibrationError,
    TData
  >(
    queryKeyFn({
      path: '/api/contents/vnmeter/{content_id}',
      operationId: 'getNoiseVibration',
      variables
    }),
    ({ signal }) =>
      fetchGetNoiseVibration({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  )
}

export type GetIccPathParams = {
  contentId: string
}

export type GetIccError = Fetcher.ErrorWrapper<{
  status: 401
  payload: Schemas.Unauthorized
}>

export type GetIccVariables = {
  pathParams: GetIccPathParams
} & BackendContext['fetcherOptions']

/**
 * ICC情報取得
 */
export const fetchGetIcc = (variables: GetIccVariables, signal?: AbortSignal) =>
  backendFetch<Schemas.Icc, GetIccError, undefined, {}, {}, GetIccPathParams>({
    url: '/api/contents/weatherdaemon/{contentId}',
    method: 'get',
    ...variables,
    signal
  })

/**
 * ICC情報取得
 */
export const useGetIcc = <TData = Schemas.Icc>(
  variables: GetIccVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Icc, GetIccError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useBackendContext(options)
  return reactQuery.useQuery<Schemas.Icc, GetIccError, TData>(
    queryKeyFn({
      path: '/api/contents/weatherdaemon/{content_id}',
      operationId: 'getIcc',
      variables
    }),
    ({ signal }) => fetchGetIcc({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  )
}

export type GetCameraPathParams = {
  contentId: string
}

export type GetCameraError = Fetcher.ErrorWrapper<{
  status: 401
  payload: Schemas.Unauthorized
}>

export type GetCameraVariables = {
  pathParams: GetCameraPathParams
} & BackendContext['fetcherOptions']

export type GetZeroAccidentPathParams = {
  contentId: string
}

export type GetZeroAccidentVariables = {
  pathParams: GetZeroAccidentPathParams
} & BackendContext['fetcherOptions']

/**
 * CAMERA情報取得
 */
export const fetchGetCamera = (
  variables: GetCameraVariables,
  signal?: AbortSignal
) =>
  backendFetch<
    Schemas.Camera,
    GetCameraError,
    undefined,
    {},
    {},
    GetCameraPathParams
  >({
    url: '/api/contents/camera/{contentId}',
    method: 'get',
    ...variables,
    signal
  })

/**
 * CAMERA情報取得
 */
export const useGetCamera = <TData = Schemas.Camera>(
  variables: GetCameraVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Camera, GetCameraError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useBackendContext(options)
  return reactQuery.useQuery<Schemas.Camera, GetCameraError, TData>(
    queryKeyFn({
      path: '/api/contents/camera/{content_id}',
      operationId: 'getCamera',
      variables
    }),
    ({ signal }) => fetchGetCamera({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  )
}

export type HashLoginQueryParams = {
  /**
   * 個々のサイネージに予め割り当てられたコード
   */
  code?: string
}

export type HashLoginError = Fetcher.ErrorWrapper<{
  status: 401
  payload: Schemas.Unauthorized
}>

export type HashLoginResponse = {
  /**
   * ここで取得したtoken を Header Bearer にセットして以降のAPIを呼び出す。
   * 例： ReeVo8kushe5ohTh8die2aiT5ibiepoS
   */
  token?: string
}

export type HashLoginVariables = {
  queryParams?: HashLoginQueryParams
} & BackendContext['fetcherOptions']

/**
 * 個々のサイネージに予め割り当てられたコード（例：shou2zohXiem8Lae2aetierael7muce6）をパラメータとして渡し、JWTokenを取得する
 */
export const fetchHashLogin = (
  variables: HashLoginVariables,
  signal?: AbortSignal
) =>
  backendFetch<
    HashLoginResponse,
    HashLoginError,
    undefined,
    {},
    HashLoginQueryParams,
    {}
  >({ url: '/api/hash_login', method: 'get', ...variables, signal })

/**
 * 個々のサイネージに予め割り当てられたコード（例：shou2zohXiem8Lae2aetierael7muce6）をパラメータとして渡し、JWTokenを取得する
 */
export const useHashLogin = <TData = HashLoginResponse>(
  variables: HashLoginVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<HashLoginResponse, HashLoginError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useBackendContext(options)

  return reactQuery.useQuery<HashLoginResponse, HashLoginError, TData>(
    queryKeyFn({
      path: '/api/hash_login',
      operationId: 'hashLogin',
      variables
    }),
    ({ signal }) => fetchHashLogin({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  )
}

export type GetWebsocketMessagePathParams = {
  contentId: string
}

export type GetWebsocketMessageError = Fetcher.ErrorWrapper<undefined>

export type GetWebsocketMessageResponse = {
  type?: 'slides' | 'message'
  slides?: {
    id?:
      | 'picture'
      | 'movie'
      | 'html'
      | 'schedule'
      | 'weather'
      | 'wbgt'
      | 'vnmeter'
      | 'weatherdaemon'
      | 'camera'
    type?: string
    duration?: number
    transition?: 'fade' | 'cube' | 'coverflow' | 'flip' | 'cards' | 'creative'
  }[]
  message?: string
}

export type GetWebsocketMessageVariables = {
  pathParams: GetWebsocketMessagePathParams
} & BackendContext['fetcherOptions']

/**
 * wsでOnMessage時に送り込まれるペイロードの定義
 */
export const fetchGetWebsocketMessage = (
  variables: GetWebsocketMessageVariables,
  signal?: AbortSignal
) =>
  backendFetch<
    GetWebsocketMessageResponse,
    GetWebsocketMessageError,
    undefined,
    {},
    {},
    GetWebsocketMessagePathParams
  >({ url: '/api/ws/message', method: 'get', ...variables, signal })

/**
 * wsでOnMessage時に送り込まれるペイロードの定義
 */
export const useGetWebsocketMessage = <TData = GetWebsocketMessageResponse>(
  variables: GetWebsocketMessageVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetWebsocketMessageResponse,
      GetWebsocketMessageError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useBackendContext(options)
  return reactQuery.useQuery<
    GetWebsocketMessageResponse,
    GetWebsocketMessageError,
    TData
  >(
    queryKeyFn({
      path: '/api/ws/message',
      operationId: 'getWebsocketMessage',
      variables
    }),
    ({ signal }) =>
      fetchGetWebsocketMessage({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  )
}

export type QueryOperation =
  | {
      path: '/api/system'
      operationId: 'getSystem'
      variables: GetSystemVariables
    }
  | {
      path: '/api/slideshow'
      operationId: 'getSlideshow'
      variables: GetSlideshowVariables
    }
  | {
      path: '/api/contents/picture/{content_id}'
      operationId: 'getPicture'
      variables: GetPictureVariables
    }
  | {
      path: '/api/contents/movie/{content_id}'
      operationId: 'getMovie'
      variables: GetMovieVariables
    }
  | {
      path: '/api/contents/html/{content_id}'
      operationId: 'getHtml'
      variables: GetHtmlVariables
    }
  | {
      path: '/api/contents/schedule/{content_id}'
      operationId: 'getSchedule'
      variables: GetScheduleVariables
    }
  | {
      path: '/api/contents/weather/{content_id}'
      operationId: 'getWeather'
      variables: GetWeatherVariables
    }
  | {
      path: '/api/contents/wbgt/{content_id}'
      operationId: 'getWbgt'
      variables: GetWbgtVariables
    }
  | {
      path: '/api/contents/vnmeter/{content_id}'
      operationId: 'getNoiseVibration'
      variables: GetNoiseVibrationVariables
    }
  | {
      path: '/api/contents/weatherdaemon/{content_id}'
      operationId: 'getIcc'
      variables: GetIccVariables
    }
  | {
      path: '/api/contents/zeroaccident/{content_id}'
      operationId: 'getZeroaccident'
      variables: GetZeroAccidentVariables
    }
  | {
      path: '/api/contents/aicontent/{content_id}'
      operationId: 'getAiContent'
      variables: GetAiContentVariables
    }
  | {
      path: '/api/contents/camera/{content_id}'
      operationId: 'getCamera'
      variables: GetCameraVariables
    }
  | {
      path: '/api/hash_login'
      operationId: 'hashLogin'
      variables: HashLoginVariables
    }
  | {
      path: '/api/ws/message'
      operationId: 'getWebsocketMessage'
      variables: GetWebsocketMessageVariables
    }
