import { ExtendedNoiseVibration } from '../../types'
import { getNoiseImage, getVibrationImage } from './utils'
import { format } from 'date-fns'
import { useColorStore } from 'src/zustand/useColorStore'

// import './normalize.css'
import './fonts.css'
import './style.css'
import './vibration.css'

export const ExamplePageDefault = ({
  noiseValue,
  vibrationValue,
  contractName
}: ExtendedNoiseVibration) => {  
  const { tcolor, bcolor } = useColorStore()

  const noises = getNoiseImage(Number(noiseValue))
  const vibrations = getVibrationImage(Number(vibrationValue))

  return (
  <div className="container vibration" style={{ background : bcolor }}>
    <div className="page-contents">
      <header className="header">
        <h1 className="header__title" style={{ color: tcolor }}>現在の騒音・振動</h1>
        <div className="header__datetime">
          <div className="header__date">
              <span>{ format(new Date(), 'M') }</span>
            <span className="header__datetime-unit">月</span>
              <span>{ format(new Date(), 'd') }</span>
            <span className="header__datetime-unit">日</span>
          </div>
          <div className="header__time">
            <span className="header__datetime-unit header__time-unit">{ format(new Date(), 'a') }</span>
              <span>{ format(new Date(), 'HH:mm') }</span>
          </div>
        </div>
      </header>

      <main className="contents">
        <section className="vibration-content" style={{ width : '86vw' }}>
            <h2 className="vibration-content__title">{ contractName }</h2>
          <div className="vibration-content__list">
            <section className="vibration-content__item" style={{ width : '40vw' }}>
              <header className="vibration-content__header">
                <h3 className="vibration-content__item-heading" style={{ width : '8vw' }}>騒<br />音</h3>
                <div className="vibration-content__item-value font-numbers" style={{ width : '82vw' }}><span>{ noiseValue }</span></div>
                <div className="vibration-content__item-unit" style={{ width : '40vw' }}>dB</div>
              </header>
            </section>
            <section className="vibration-content__item" style={{ width : '40vw' }}>
              <header className="vibration-content__header">
                <h3 className="vibration-content__item-heading" style={{ width : '8vw' }}>振<br />動</h3>
                <div className="vibration-content__item-value font-numbers" style={{ width : '82vw' }}><span>{ vibrationValue }</span></div>
                <div className="vibration-content__item-unit" style={{ width : '40vw' }}>dB</div>
              </header>
            </section>
          </div>
        </section>
      </main>

      </div>
    <footer className="footer">
      <div className="footer__note">
        <div className="marquee">
          <span></span>
        </div>
      </div>
    </footer>
  </div>
  )
}
