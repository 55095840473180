import { useHashLogin } from 'generated/backendComponents'
import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { useRenderEffect } from 'src/hooks'
import { useAuthContext, useSetAuthContext } from 'src/contexts/AuthContext'

import { useSizeStore } from 'src/zustand/useSizeStore'

export const Auth = () => {
  const token = useAuthContext()
  const setAuth = useSetAuthContext()
  const { setSize } = useSizeStore();

  const { code } = useParams()
  const [searchParams] = useSearchParams();

  const size = ( searchParams.get('size') !== null ) ? searchParams.get('size') : 'default'
  console.log('in Auth: ' + size)
  const navigate = useNavigate()

  const [success, setSuccess] = useState(false)

//  setColor('linear-gradient(151.49deg, #FAFF00 37.81%, #00BF7A 87.07%)')
//  setColor('linear-gradient(#e66465, #9198e5)')

//  const url = new URL(request.url);
//  const size = url.searchParams.get("size");
//  console.log(size)
  
  useRenderEffect(() => {
    code && localStorage.setItem('code', code)
    if (size !== null) {
      setSize( size )
    } else {
      setSize( 'default' )
    }
    setAuth(null)
  })

  useHashLogin(
    { queryParams: { code } },
    {
      onSuccess: (data) => {
        if (!data.token) return
        setAuth(data.token)
        setSuccess(true)
      },
      retry: true,
      retryDelay: 1000 * 60 * 3,
      cacheTime: 0
    }
  )

  useEffect(() => {
    if (token && success) {
      navigate('/slideshow')
    }
  }, [navigate, token, success])

  return <div>認証中...</div>
}
