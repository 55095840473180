import { Icc } from 'generated/backendSchemas'
import { Util } from 'src/utils'
import { getWindDirectionText, getWindImage } from '../util'
import { format } from 'date-fns'
import { useColorStore } from 'src/zustand/useColorStore'

export const ExamplePageDefault = ({
  average_wind_speed,
  barometric_pressure,
  highest,
  humidity,
  lowest,
  max_wind_speed,
  rainfall,
  rainfall_24,
  rainfall_day,
  sunrise_time,
  sunset_time,
  temperature,
  uvi,
  wbgt_value,
  wind_direction,
  display_date,
  contract_name
}: Icc) => {

  const { tcolor, bcolor } = useColorStore()

  return (
  <div className="container vibration" style={{ background : bcolor}}>
    <div className="page-contents">
      <header className="header" style={{ height : '16vh' }}>
        <h1 className="header__title" style={{ color: tcolor }}>気象計</h1>
        <div className="header__datetime">
          <div className="header__date">
              <span>{ format(new Date(), 'M') }</span>
            <span className="header__datetime-unit">月</span>
              <span>{ format(new Date(), 'd') }</span>
            <span className="header__datetime-unit">日</span>
          </div>
          <div className="header__time">
            <span className="header__datetime-unit header__time-unit">{ format(new Date(), 'a') }</span>
              <span>{ format(new Date(), 'HH:mm') }</span>
          </div>
        </div>
      </header>
      <div className="Weather_gauge horizontal" style={{ height: '78vh', backgroundColor : 'transparent', overflow : 'hidden', paddingTop : '0' }}>
          <div style={{ display : 'flex', justifyContent : 'space-between', fontSize: '3vh', fontWeight: 'bold', width: '100%', textAlign: 'left' }}>
            <div style={{ fontSize : '2vw', fontWeight : 'bold', marginLeft : '1vw', color: tcolor }}>{ contract_name }</div>
            <div style={{ textAlign : 'right', color: tcolor}}>
              <span>{ display_date && format(new Date(display_date), 'M') }</span>
              <span>月</span>
              <span>{ display_date && format(new Date(display_date), 'd') }</span>
              <span>日</span>
              <span>{ display_date && format(new Date(display_date), 'a') }</span>
              <span>{ display_date && format(new Date(display_date), 'HH:mm') }</span>
              <span>発表</span>
            </div>
          </div>

          <ul className="WeatherGaugeList">
            <li className="WeatherGaugeList__Item" style={{ height : '35vh' }}>
              <h2 className="WeatherGaugeList__Title WeatherGaugeList__Title--TempLCD">
                気温
              </h2>
              <div className="WeatherGaugeList__Value" style={{ fontSize : '5vw', textAlign : 'right' }}>
                {temperature}
                <span className="WeatherGaugeList__Value--Unit" style={{ fontSize : '3vw' }}>℃</span>
              </div>

              <h2 className="WeatherGaugeList__Title WeatherGaugeList__Title--HumidityLCD" style={{ paddingTop : '1vw' }}>
                湿度
              </h2>
              <div className="WeatherGaugeList__Value" style={{ fontSize : '5vw', textAlign : 'right' }}>
                {humidity}
                <span className="WeatherGaugeList__Value--Unit" style={{ fontSize : '3vw' }}>%</span>
              </div>
            </li>

            <li className="WeatherGaugeList__Item" style={{ height : '35vh' }}>
              <h2 className="WeatherGaugeList__Title WeatherGaugeList__Title--RainfallLCD">
                雨量
              </h2>
              <p className="WeatherGaugeList__Value--Unit" style={{ fontSize : '2vw', paddingRight : '5%', textAlign : 'right', fontWeight: 'bold' }}>
                1時間雨量
                <span className="WeatherGaugeList__Value--Numbers" style={{ fontSize : '5vw', lineHeight : '1.0' }}>{rainfall}</span>
                <span className="WeatherGaugeList__Value--Unit" style={{ fontSize : '2vw' }}>mm</span>
              </p>
              <div className="" style={{ display: 'flex', justifyContent: 'flex-end', paddingRight : '5%' }}>
                <div className="WeatherGaugeList__Other--Day" style={{ fontSize: '2vw' }}>
                  24時間雨量
                  <span className="WeatherGaugeList__Other--Numbers" style={{ fontSize : '4vw', lineHeight : '4vw' }}>
                    {rainfall_24}
                  </span>
                  mm
                </div>
              </div>

              <div className="" style={{ display: 'flex', justifyContent: 'flex-end', paddingRight : '5%' }}>
                <div className="WeatherGaugeList__Other--Day" style={{ fontSize: '2vw', width: '100%', textAlign: 'right' }}>
                  1日雨量
                  <span className="WeatherGaugeList__Other--Numbers" style={{ fontSize : '4vw', lineHeight : '4vw' }}>
                    {rainfall_day}
                  </span>
                  mm
                </div>
              </div>
            </li>

            <li className="WeatherGaugeList__Item WeatherGaugeList__Item--Speed" style={{ height : '35vh' }}>
              <h2 className="WeatherGaugeList__Title WeatherGaugeList__Title--SpeedLCD">
                風速
              </h2>
              <p className="WeatherGaugeList__Value _WeatherGaugeList__Value--Average" style={{ fontSize : '2vw', padding : '5%', textAlign : 'right' }}>
                平均風速
                <span className="WeatherGaugeList__Value--Numbers" style={{ fontSize : '5vw', lineHeight : '10vh' }}>
                  {average_wind_speed}
                </span>
                m/s
              </p>
              <p className="WeatherGaugeList__Value _WeatherGaugeList__Value--Maximum" style={{ fontSize : '2vw', padding : '5%', textAlign : 'right' }}>
                最大風速
                <span className="WeatherGaugeList__Value--Numbers" style={{ fontSize : '5vw', lineHeight : '6vh' }}>
                  {max_wind_speed}
                </span>
                m/s
              </p>
            </li>

            <li className="WeatherGaugeList__Item WeatherGaugeList__Item--Direction" style={{ height : '35vh' }}>
              <h2 className="WeatherGaugeList__Title WeatherGaugeList__Title--DirectionLCD" style={{ marginBottom : '0' }}>
                風向き
              </h2>
              <div className="WeatherGaugeList__DirectionWrapper" style={{ paddingTop : '0', marginTop : '0' }}>
                <img
                  src={getWindImage(wind_direction ?? 0)}
                  alt=""
                  className="WeatherGaugeList__Value WeatherGaugeList__DirectionIcon"
                  style={{ height : '28vh', width : '28vh' }}
                />

                <div className="WeatherGaugeList__DirectionInner" style={{ position : 'absolute', gap : '0' }}>
                  <p className="WeatherGaugeList__Value WeatherGaugeList__Value--DirectionDegrees" style={{ fontSize : '3vh' }}>
                    {wind_direction ?? 0}°
                  </p>
                  <p className="WeatherGaugeList__Value WeatherGaugeList__Value--Direction" style={{ fontSize : '3vh' }}>
                    {getWindDirectionText(wind_direction ?? 0)}
                  </p>
                </div>

              </div>
            </li>

            <li className="WeatherGaugeList__Item _WeatherGaugeList__Item--Pressure" style={{ height : '35vh' }}>
              <h2 className="WeatherGaugeList__Title WeatherGaugeList__Title--PressureLCD">
                気圧
              </h2>
              <div className="WeatherGaugeList__Value WeatherGaugeList__Value--Pressure" style={{ fontSize : '5vw', display : 'flex', justifyContent : 'flex-end' }}>
                <div>{barometric_pressure}</div>
                <div className="WeatherGaugeList__Value--Unit" style={{ fontSize : '2vw', lineHeight : '7vw' }}> hPa</div>
              </div>
            </li>

            <li className="WeatherGaugeList__Item WeatherGaugeList__Item--Wbgt" style={{ height : '35vh' }}>
              <h2 className="WeatherGaugeList__Title WeatherGaugeList__Title--White WeatherGaugeList__Title--Wbgt">
                換算WBGT
              </h2>
              <div className="WeatherGaugeList__Value WeatherGaugeList__Value--Pressure" style={{ fontSize : '6vw', textAlign : 'center', lineHeight : '16vw' }}>
                { (temperature) && Math.floor( temperature ) }
                <span className="WeatherGaugeList__Value--Unit" style={{ fontSize : '3vw' }}>℃</span>
              </div>
            </li>

          </ul>
        </div>
      </div>


      <footer className="footer" style={{ height : '5vh' }}>
        <div className="footer__note">
          <div className="marquee">
            <span>TEST</span>
          </div>
        </div>
      </footer>


    </div>

  )
}
